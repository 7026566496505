/* Producer Profile */
.producer-info {
  height: auto;
  box-shadow: 0 0 20px 0 rgba(187, 187, 187, 0.65);
  margin-top: 0.5rem;
}
.producer-profile-image-container {
  width: 314px;
}
.producer-profile-image {
  object-fit: cover;
}
.producer-profile-head {
  font-family: Anjhay;
  line-height: 0.7;
}
.producer-bio-container {
  width: -moz-available;
  width: -webkit-fill-available;
}
.producer-button-view {
  max-width: 95%;
  width: 144px;
  height: 38px;
}
.producer-profile-gallery-card {
  width: 224px;
  height: 399px;
  padding: 14px;
  @apply m-2;
}
.producer-profile-gallery-icon {
  filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 1));
}
.producer-profile-card-image {
  height: 260px;
  width: 195px;
  overflow: hidden;
}
.producer-profile-galleries {
  justify-content: center;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 768px) {
  .producer-bio-container {
    padding-bottom: 0.75rem !important;
  }
  .producer-info {
    flex-direction: column;
    height: auto;
    margin-top: 0;
  }
  .producer-profile-image-container {
    width: 100vw;
  }
  .producer-profile-image {
    object-fit: contain;
  }
  .producer-button {
    position: absolute;
    top: -21px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .button-div {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 520px) {
  .producer-info {
    flex-direction: column;
    height: auto;
  }
  .producer-profile-card-image {
    max-width: 100%;
    max-height: 260px;
    height: 260px;
    width: auto;
  }
  .producer-profile-gallery-card {
    padding: 2vw;
    width: calc(50vw - 4px);
    max-height: 399px;
    height: auto;
    margin: 0px;
    margin-bottom: 8px;
  }
  .producer-button {
    height: 36px;
  }
  .producer-profile-galleries {
    justify-content: space-between;
  }
}

/* These are css classes for the social icons - They were removed from the icons file because they break AMP */
.st0-producer-profile-instagram {
  fill: none;
  stroke: #000000;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st1-producer-profile-instagram {
  fill: none;
  stroke: #000000;
  stroke-width: 2.5;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st0-producer-profile-facebook {
  fill: none;
  stroke: #000000;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st0-producer-profile-tiktok {
  fill: none;
  stroke: #000000;
  stroke-width: 2;
}
