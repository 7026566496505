/*
Footer with Insider button
 - css hatches on the background
 - rotated by transform because of strange background colour when gradient is rotated directly
*/
.inside-footer:before {
  content: '';
  position: absolute;
  background-image: linear-gradient(
    transparent 42.86%,
    #e30604 42.86%,
    #e30604 50%,
    transparent 50%,
    transparent 92.86%,
    #e30604 92.86%,
    #e30604 100%
  );
  background-size: 1rem 1rem;
  left: -20vw;
  top: -50vh;
  width: 200vw;
  height: 200vw;
  z-index: -1;
  transform: rotate(-42deg);
}

@media (min-width: 520px) {
  .inside-footer:before {
    left: -50vw;
    top: -50vh;
    width: 150vw;
    height: 150vw;
  }
}
