.news-group li .time-section {
  z-index: 2;
  flex: 0 0 auto;
}

.news-group li:not(:last-child):after {
  content: '';
  width: 1px;
  position: absolute;
  top: 2rem;
  height: 100%;
  left: 3.25rem;
  z-index: 1;
  background: #000;
}
