/*
Print styles
----
 - remove header & footer
 - all print styles are !important
*/

.print-only {
  display: none !important;
}

@media print {
  .print-none {
    display: none !important;
    width: 0 !important;
  }

  .print-only {
    display: block !important;
  }

  /* General components hidden by print */
  .header,
  .footer {
    display: none !important;
  }

  /* No shadows */
  [class*='shadow'] {
    box-shadow: none !important;
  }

  /* Helper classes for spacing reset */
  .print-p-0 {
    padding: 0 !important;
  }

  .print-m-0 {
    margin: 0 !important;
  }

  .print-w-full {
    width: 100% !important;
    max-width: none !important;
  }

  .print-border {
    border: 1px solid #ccc !important;
    padding: 0.25rem;
  }

  .print-heading h2 {
    font-size: 1.5rem;
    padding-left: 0 !important;
    margin-left: 0 !important;
  }

  /* Container editor-ui-layout padding reset */
  .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: auto !important;
  }

  .bg-grey-300,
  .border-grey-300 {
    -webkit-print-color-adjust: exact;
  }
}
