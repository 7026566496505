/**
* Buttons
*/
@variants responsive {
  .btn {
    @apply py-4 px-6 border-0 uppercase text-base leading-none rounded;
    letter-spacing: 0.3px;
  }

  .btn:disabled,
  .btn.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  /* Btn sizing */
  .btn-xs {
    @apply text-sm py-2;
  }

  @screen xs {
    .btn-xs {
      @apply py-3;
    }
  }

  @screen sm {
    .btn-xs {
      @apply text-base;
    }
  }

  .btn-sm {
    @apply py-4;
  }

  @screen sm {
    .btn-sm {
      @apply py-3;
    }
  }

  /* Btn colors */
  .btn-primary {
    @apply bg-black text-white;
  }

  .btn-outlined {
    @apply py-3 leading-tight;
    border: 2px solid #000;
  }

  @screen sm {
    .btn-outlined.btn-sm {
      @apply py-2;
    }
  }

  .btn-inside {
    @apply bg-inside text-white;
  }
}

.btn-shadow:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.btn-primary:hover {
  @apply bg-black text-white btn-shadow;
}

.btn-outlined:hover {
  @apply btn-shadow;
}

.btn-inside:hover {
  @apply text-white btn-shadow;
  background-color: #b79148;
}

.layout-bg-dark .btn-inside:hover {
  @apply btn-shadow;
}
