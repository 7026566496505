/*
Newsticker article item
 - needs line after the time element (0.5rem below)
 - last item without line
*/

.news-link::before {
  content: '';
  position: absolute;
  left: 1.5rem;
  top: 2rem;
  bottom: 0;
  width: 1px;
  background-color: #000;
}

.news-link-image {
  height: 68px;
  width: 68px;
}

@screen md {
  .news-link::before {
    top: 2.375rem;
    left: 1.875rem;
  }

  .news-link-image {
    height: 104px;
    width: 104px;
  }

  .newsTickerContent {
    min-width: 636px;
  }
}

/* Last child - no line */
.news-link:last-child::before {
  display: none;
}
