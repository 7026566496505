.article-container {
  @apply mx-auto;
}

.page-type--article #page_article {
  @apply md:pt-0;
}

.article-content a {
  @apply underline text-red;
}

.article-content h2 {
  @apply font-sans font-bold mb-4 text-xl leading-tight;
}

.article-content h3 {
  @apply text-lg font-sans font-bold mb-2 uppercase;
}

.article-content h4,
.article-content h5,
.article-content h6 {
  @apply font-bold text-md mb-2;
}

.article-content p {
  @apply mb-5;
}

.article-content a {
  @apply underline text-red;
}

.article-content ol {
  @apply list-decimal py-4 pl-5;
}

.article-content ul {
  @apply list-square py-4 pl-5;
}

.article-content ul > li,
.article-content ol > li {
  @apply pb-4;
}

.article-content blockquote {
  @apply p-4 mb-5 mt-4 block w-full list-square bg-grey-100 font-serif text-md relative border-8 border-white;
}

.article-content blockquote > p {
  @apply mb-0;
}

.article-content blockquote::before {
  content: '';
  width: 45px;
  height: 72px;
  position: absolute;
  left: -6px;
  z-index: 0;
  top: -6px;
  background-color: #e30604;
  clip-path: polygon(0% 0%, 100% 0%, 100% 8%, 13% 8%, 13% 95%, 0% 100%);
}

.article-caption::before {
  content: '';
  width: 47px;
  height: 30px;
  position: absolute;
  left: -8px;
  z-index: 0;
  top: -8px;
  background-color: currentColor;
  clip-path: polygon(0 0, 100% 0, 100% 8px, 8px 8px, 8px 90%, 0 100%);
}

@screen sm {
  .article-content h2 {
    @apply text-4xl mb-6;
  }

  .article-content h3 {
    @apply text-2xl mb-4;
  }

  .article-content h4,
  .article-content h5,
  .article-content h6 {
    @apply text-xl mb-4;
  }

  .article-content p {
    @apply mb-8;
  }

  .article-content ol,
  .article-content ul {
    @apply pl-6;
  }

  .article-content ul > li,
  .article-content ol > li {
    @apply pb-6;
  }

  .article-content blockquote {
    @apply mb-8 mt-6 text-xl p-6 border-10;
  }

  .article-content blockquote::before {
    width: 50px;
    height: 90px;
    clip-path: polygon(0% 0%, 100% 0%, 100% 11%, 20% 11%, 20% 95%, 0% 100%);
    left: -10px;
    top: -10px;
  }
  .article-content .newsletter-disclaimer {
    @apply mb-6;
  }
}

@screen md {
  .article-container {
    @apply w-5/6;
  }

  .article-caption::before {
    content: '';
    width: 96px;
    height: 48px;
    position: absolute;
    left: -12px;
    z-index: 0;
    top: -12px;
    background-color: currentColor;
    clip-path: polygon(0 0, 100% 0, 100% 12px, 12px 12px, 12px 90%, 0 100%);
  }
}

/* Vertical colors background variants */
.vertical-beauty .article-content blockquote::before {
  background-color: var(--vertical-beauty);
}

.vertical-family .article-content blockquote::before {
  background-color: var(--vertical-family);
}

.vertical-fashion .article-content blockquote::before {
  background-color: var(--vertical-fashion);
}

.vertical-health .article-content blockquote::before {
  background-color: var(--vertical-health);
}

.vertical-vip-selection .article-content blockquote::before {
  background-color: var(--vertical-deals);
}

.vertical-inside .article-content blockquote::before {
  background-color: var(--vertical-inside);
  background-image: linear-gradient(130deg, var(--vertical-inside) 37%, #d9bf88);
}

/* Vertical colors for links */
.vertical-beauty .article-content a {
  color: var(--vertical-beauty);
}

.vertical-family .article-content a {
  color: var(--vertical-family);
}

.vertical-fashion .article-content a {
  color: var(--vertical-fashion);
}

.vertical-health .article-content a {
  color: var(--vertical-health);
}

.vertical-vip-selection .article-content a {
  color: var(--vertical-deals);
}

.vertical-inside .article-content a {
  color: var(--vertical-inside);
}

.outbrain-smartfeed-wrapper {
  display: flex;
}

.ob-smartfeed-wrapper {
  padding: 0 !important;
}

/*
Breadcrumb component
 - shadows on sides
 - hiding scrollbars
*/
.article-breadcrumb::before,
.article-breadcrumb::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 2px;
  width: 1rem;
  z-index: 1;
}

.article-breadcrumb::before {
  left: 0;
  background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
}

.article-breadcrumb::after {
  width: 1rem;
  right: 0;
  background-image: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
}

.article-breadcrumb::-webkit-scrollbar {
  display: none;
}

.article-breadcrumb {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.quote-box {
  width: 95%;
}

@screen sm {
  .quote-box {
    max-width: 80%;
  }
}

.quote-box .article-content li::marker {
  @apply text-red;
}

.vertical-beauty .quote-box li::marker {
  color: var(--vertical-beauty);
}

.vertical-family .quote-box li::marker {
  color: var(--vertical-family);
}

.vertical-fashion .quote-box li::marker {
  color: var(--vertical-fashion);
}

.vertical-health .quote-box li::marker {
  color: var(--vertical-health);
}

.vertical-vip-selection .quote-box li::marker {
  color: var(--vertical-deals);
}

.vertical-inside .quote-box li::marker {
  color: var(--vertical-inside);
}

.affiliate-disclaimer {
  width: 95%;
}

[data-affiliate]::before {
  width: 1.1rem;
  height: 1rem;
  display: inline-block;
  position: relative;
  top: 0.25rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#e30604" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>');
}

@screen sm {
  .affiliate-disclaimer {
    max-width: 80%;
  }

  [data-affiliate]::before {
    width: 1.2rem;
  }
}

.affiliate-edge {
  width: 72px;
  height: 45px;
  position: absolute;
  z-index: 0;
  background-color: #e30604;
  clip-path: polygon(0% 0%, 100% 0%, 100% 21%, 13% 21%, 13% 90%, 0% 100%);
}
.info-block-header {
  top: -2rem;
  left: -0.5rem;
}
.affiliate-box-top {
  top: -9px;
  left: -9px;
}
.affiliate-box-bottom {
  transform: rotate(180deg);
  bottom: -9px;
  right: -9px;
}
.affiliate-edge-bg {
  width: 87px;
  height: 60px;
  position: absolute;
  z-index: 0;
  background-color: #fff;
  clip-path: polygon(0% 0%, 100% 0%, 100% 21%, 13% 21%, 13% 90%, 0% 100%);
}
.affiliate-box-top-bg {
  top: -9px;
  left: -9px;
}
.affiliate-box-bottom-bg {
  transform: rotate(180deg);
  bottom: -9px;
  right: -9px;
}

@screen sm {
  .affiliate-edge {
    width: 90px;
    height: 50px;
    clip-path: polygon(0% 0%, 100% 0%, 100% 27%, 15% 27%, 15% 85%, 0% 100%);
  }
  .affiliate-box-top {
    left: -14px;
    top: -14px;
  }
  .affiliate-box-bottom {
    bottom: -14px;
    right: -14px;
  }

  .affiliate-edge-bg {
    width: 110px;
    height: 70px;
    background-color: #fff;
    content: '';
    clip-path: polygon(0% 0%, 100% 0%, 100% 27%, 15% 27%, 15% 85%, 0% 100%);
  }
  .affiliate-box-top-bg {
    left: -14px;
    top: -14px;
  }

  .affiliate-box-bottom-bg {
    bottom: -14px;
    right: -14px;
  }
}

/* Vertical colors for affiliate disclaimer */

.vertical-beauty .affiliate-edge {
  background-color: var(--vertical-beauty);
}

.vertical-family .affiliate-edge {
  background-color: var(--vertical-family);
}

.vertical-fashion .affiliate-edge {
  background-color: var(--vertical-fashion);
}

.vertical-health .affiliate-edge {
  background-color: var(--vertical-health);
}

.vertical-vip-selection .affiliate-edge {
  background-color: var(--vertical-deals);
}

.vertical-inside .affiliate-edge {
  background-color: var(--vertical-inside);
}

.vertical-beauty a[data-affiliate] {
  color: var(--vertical-beauty);
}

.vertical-family a[data-affiliate] {
  color: var(--vertical-family);
}

.vertical-fashion a[data-affiliate] {
  color: var(--vertical-fashion);
}

.vertical-health a[data-affiliate] {
  color: var(--vertical-health);
}

.vertical-vip-selection a[data-affiliate] {
  color: var(--vertical-deals);
}

.vertical-inside a[data-affiliate] {
  color: var(--vertical-inside);
}

.vertical-beauty [data-affiliate]::before {
  content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#bf2c67" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>');
}

.vertical-family [data-affiliate]::before {
  content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#55bfd3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>');
}

.vertical-fashion [data-affiliate]::before {
  content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#4f1e4b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>');
}

.vertical-health [data-affiliate]::before {
  content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#9bc751" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>');
}

.vertical-vip-selection [data-affiliate]::before {
  content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#ff6600" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>');
}

.vertical-inside [data-affiliate]::before {
  content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#b79148" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>');
}

.affiliate-text > ol,
.affiliate-text > ul {
  overflow: hidden;
  padding-left: 2rem;
}
