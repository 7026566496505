:root {
  --max-lines: 6; /* maximum lines for truncating */
  --vertical-beauty: #bf2c67;
  --vertical-family: #55bfd3;
  --vertical-fashion: #4f1e4b;
  --vertical-health: #9bc751;
  --vertical-inside: #e30604; /*paywall color =>#b79148;*/
  --vertical-deals: #ff6600;
  --vertical-bunte-moments: #f39200;
}

body::before {
  content: 'mobile';
  display: none;
}

@screen sm {
  body::before {
    content: 'tablet';
  }
}

@screen md {
  body::before {
    content: 'desktop';
  }
}
