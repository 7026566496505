/* Star Profile */
.star-content h2 {
  @apply font-sans font-bold mb-6 mt-6 text-3xl md:text-4xl leading-tight;
}
.star-content h3 {
  @apply text-2xl mb-4 font-sans font-bold uppercase;
}
.star-content p {
  @apply font-serif mb-8 text-base md:text-lg;
}
.star-content a {
  @apply underline text-red;
}

/* Star Overview */
.star-overview-img img {
  width: 100%;
}

.star-anchor {
  transform: translateY(-50vh);
}

.float-menu {
  top: 88px;
}

@screen sm {
  .float-menu {
    top: 108px;
  }
}

@screen md {
  .float-menu {
    top: 64px;
  }
}
