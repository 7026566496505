.author-image {
  height: 100%;
  width: 100%;
}

@screen sm {
  .author-image {
    height: 24rem;
    width: 24rem;
  }
}
