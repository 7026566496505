/*
Channel headline custom styles
 - small position correction regarding the main title
 - "fake" underline below "Read more" link
*/

.channel-link:hover::after,
.channel-link-hovered::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.5rem;
  height: 2px;
  width: 100%;
  background-color: #000;
}
.channel-link {
  color: #000;
}

/* This is currently only used for an A/B test for TT-2965
   Can be removed once the A/B test is complete.
*/
.channel-link-alle:hover::after,
.channel-link-alle-hovered::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.2rem;
  z-index: 10;
  height: 2px;
  width: 100%;
  background-color: #000;
}

@screen md {
  .channel-link-alle:hover::after,
  .channel-link-alle-hovered::after {
    bottom: -0.65rem;
  }
}

.channel-link-alle {
  color: #000;
}
