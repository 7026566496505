/* Ads styles */

/**
DFP Ad styles
**/
.bunte-dfp-ad {
  margin-top: 25px;
  margin-bottom: 25px;
}
@media (min-width: 640px) {
  .bunte-dfp-ad {
    margin-top: 25px;
  }
}
@media (min-width: 980px) {
  .bunte-dfp-ad {
    margin-top: -10px;
    margin-bottom: 40px;
  }
}

/**
BFA Ad styles
**/
.fe-article-promo-content .bfa-ad-slot-populated:before {
  content: 'Anzeige';
  @apply block text-center text-grey-300 text-xs mb-2;
}

:not(.fe-promo-skyscraper):not(.bfa-ad-sticky) > .bfa-ad-slot:not(.bfa-ad-out) {
  @apply relative flex flex-col items-center justify-center;
}
:not(.fe-promo-skyscraper):not(.bfa-ad-sticky) > .bfa-ad-slot.bfa-ad-out {
  @apply relative flex flex-col justify-center;
}
.bfa-ad-slot {
  @apply text-center z-0;
  box-sizing: content-box;
  justify-content: center;
}
.bfa-ad-slot-populated.bfa-ad-slot-transparent {
  @apply mx-0 py-0;
  background: none;
}
.bfa-ad-slot-populated:not(.bfa-ad-slot-transparent) {
  @apply bg-grey-100 py-2 -mx-4;
  max-width: 100vw;
}
@screen xs {
  #mobile-gallery .bfa-ad-slot-populated:not(.bfa-ad-slot-transparent) {
    margin: 0 calc(-1 * ((100vw - 480px) / 2));
  }
}
@screen md {
  .bfa-ad-slot-populated:not(.bfa-ad-slot-transparent) {
    @apply mx-0 py-0 px-4;
    background: none;
  }
}
.bfa-ad-slot iframe {
  display: inline-block;
}
.bfa-ad-hor-1-height {
  min-height: 50px;
  flex-direction: column;
}
#bunte_phone_hor_0 {
  min-height: 75px !important;
}
@media (min-width: 640px) {
  .bfa-ad-hor-1-height {
    min-height: 150px;
  }
}
.bfa-ad-hor-height {
  min-height: 250px;
}
.bfa-ad-sticky {
  position: fixed;
  bottom: 0;
  max-height: 162px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 0.25rem 0.0625rem rgba(0, 0, 0, 0.2);
}
.bfa-ad-sticky-close {
  position: absolute;
  right: 0;
  top: -1.25rem;
  width: 2rem;
  height: 1.25rem;
  box-shadow: 0 -0.0625rem 0.25rem 0.0625rem rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 0.75rem 0 0 0;
}

/* Skyscraper ad
 - !important is needed for override tailwind 'none' class
*/
.fe-promo-skyscraper-wrapper-left {
  display: none;
}
@media (min-width: 1180px) {
  .fe-promo-skyscraper-wrapper {
    left: 980px;
    display: block !important;
  }
}

.fe-promo-skyscraper {
  top: 68px;
  padding-left: 10px;
}
@media (min-width: 1600px) {
  .fe-promo-skyscraper-wrapper-left {
    left: -190px;
    display: block !important;
  }
}

.bfa-ad-hide-oop {
  display: none;
}

@media (min-width: 1180px) {
  .bfa-ad-hide-oop {
    display: block;
  }
}
.bfa-ad-flying-carpet {
  height: 312px;
  clip: rect(0, auto, auto, 0);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.bfa-ad-slot > [id^='bunte_'] {
  flex-direction: column;
}
