.tricolorBadge::after {
  position: absolute;
  right: 0px;
  bottom: -11px;
  content: url("data:image/svg+xml,<svg width='28' height='32' viewBox='0 0 28 32' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='14' height='14' transform='matrix(-1 0 0 1 14 7)' fill='#F39200'/><rect width='14' height='14' transform='matrix(-1 0 0 1 28 0)' fill='#E30604'/><rect width='18' height='18' transform='matrix(-1 0 0 1 28 14)' fill='#FFF500'/></svg>");

  /* Using the background-image and
      its related properties to add
      the SVG content */
  background-size: 28px 32px;
  height: 32px;
  width: 28px;
}

.fullWidthBg {
  width: 200vw;
  height: 100%;
  position: absolute;
  left: -50vw;
  z-index: -1;
}

.momentsSliderPlaceholder {
  min-height: 595px;
}

.momentsSliderPlaceholderShort {
  min-height: 549px;
}

.momentsPaginationActivePage {
  color: #ddd;
}

.teaser-card-shadow {
  box-shadow: 0 0 20px 0 rgba(187, 187, 187, 0.65);
}

.moments-gradient-overlay {
  height: 136px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
}
