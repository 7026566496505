/**
Headline dot
-----
 - dot element on h1 headers
*/

h1::after {
  content: '.';
  @apply text-red;
}

.headline-dot::after {
  content: '.';
  @apply text-red;
}

h1.no-dot::after {
  content: '';
}

.vertical-beauty h1::after,
.vertical-beauty .headline-dot::after {
  color: var(--vertical-beauty);
}

.vertical-health h1::after,
.vertical-health .headline-dot::after {
  color: var(--vertical-health);
}

.vertical-family h1::after,
.vertical-family .headline-dot::after {
  color: var(--vertical-family);
}

.vertical-fashion h1::after,
.vertical-fashion .headline-dot::after {
  color: var(--vertical-fashion);
}

.vertical-deals h1::after,
.vertical-deals .headline-dot::after {
  color: var(--vertical-deals);
}

.vertical-vip-selection h1::after,
.vertical-vip-selection .headline-dot::after {
  color: var(--vertical-deals);
}

.page-type--moments h1::after,
.page-type--producerProfile h1::after,
.page-type--moments .headline-dot::after {
  color: var(--vertical-bunte-moments);
  margin-left: 0px;
}

.vertical-inside h1::after,
.vertical-inside .headline-dot::after {
  color: var(--vertical-inside);
}
