/**
Alert component
-----
 - used by GTM or other scripts, no way how to use tailwind for this
 - used inside newsletter block
*/

.alert {
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
}

.alert-success {
  background-color: #f0f7e5;
  border-color: #9bc751;
  color: #639809;
}
.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}
.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}

.alert-error {
  background-color: #fbdada;
  border-color: #e30904;
  color: #eb2300;
}

.alert p {
  @apply mb-2 text-base;
}
